<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>库存调拨详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div style="opacity: 0;height: 0;">
        <el-card class="box-card" id="printTest" style="width: 1000px;margin: 0 auto;">

          <el-row :gutter="30">
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
              <span class="title">订单号：</span>
              <span class="text">{{addPurchaseForm.wa_code}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">调出仓库：</span>
              <span class="text">{{addPurchaseForm.w_out_name}}({{addPurchaseForm.wp_out_name}})</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">调入仓库：</span>
              <span class="text">{{addPurchaseForm.w_in_name}}({{addPurchaseForm.wp_in_name}})</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">调拨日期：</span>
              <span class="text">{{addPurchaseForm.wa_date}}</span>
            </el-col>
          </el-row>

          <el-table :data="addPurchaseForm.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格" prop="ga_name">
              <template slot-scope="scope">
                <div>{{scope.row.ga_name}}</div>
                <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}-{{scope.row.gad_end_date}}</div>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="gu_name"></el-table-column>
            <el-table-column label="出库数量" prop="wai_number"></el-table-column>
          </el-table>
          <el-row>
            <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">经办人：</span>
              <span class="text">{{addPurchaseForm.wa_agent_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">备注：</span>
              <span class="text">{{addPurchaseForm.wa_remark}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">调拨人：</span>
              <span class="text">{{addPurchaseForm.add_name}}</span>
            </el-col>
            <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
              <span class="title">调拨时间：</span>
              <span class="text">{{addPurchaseForm.add_date}}</span>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <el-card class="box-card" >

      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <span class="title">订单号：</span>
          <span class="text">{{addPurchaseForm.wa_code}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">调出仓库：</span>
          <span class="text">{{addPurchaseForm.w_out_name}}({{addPurchaseForm.wp_out_name}})</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">调入仓库：</span>
          <span class="text">{{addPurchaseForm.w_in_name}}({{addPurchaseForm.wp_in_name}})</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">调拨日期：</span>
          <span class="text">{{addPurchaseForm.wa_date}}</span>
        </el-col>
      </el-row>

      <el-table :data="addPurchaseForm.goodslist" border stripe  >
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品编码" prop="ga_code"></el-table-column>
        <el-table-column label="商品名称" prop="g_name"></el-table-column>
        <el-table-column label="商品规格" prop="ga_name">
          <template slot-scope="scope">
            <div>{{scope.row.ga_name}}</div>
            <div v-if="scope.row.gad_start_date">{{scope.row.gad_start_date}}-{{scope.row.gad_end_date}}</div>
          </template>
        </el-table-column>
        <el-table-column label="单位" prop="gu_name"></el-table-column>
        <el-table-column label="出库数量" prop="wai_number"></el-table-column>
      </el-table>
      <el-row>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">经办人：</span>
          <span class="text">{{addPurchaseForm.wa_agent_name}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">备注：</span>
          <span class="text">{{addPurchaseForm.wa_remark}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">调拨人：</span>
          <span class="text">{{addPurchaseForm.add_name}}</span>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:12,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}" >
          <span class="title">调拨时间：</span>
          <span class="text">{{addPurchaseForm.add_date}}</span>
        </el-col>
      </el-row>
    </el-card>

    <el-row>
<!--      <button v-print="'#printTest'" v-if="this.$route.query.is_printing">打印</button>-->
      <el-button style="float: right;margin-top: 30px;" type="primary" v-print="'#printTest'" v-if="this.$route.query.is_printing=='true'">打印</el-button>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addPurchaseForm:{}
    }
  },
  methods: {
    //获取采购订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`warehouseallocation/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm = res.data
      // this.querInfo.s_id=res.data.s_id
      // this.getGoodsList(this.querInfo)
      // this.adddisabled=false

    },
  },
  created(){
    this.getpurchaseList()
  }
}
</script>
<style>
.el-col{
  display: flex;
}
.pirce{
  width: 200px;
}
.title{
  color: #415161;
  font-size: 13px;
}
.text{
  color: #415161;
  font-size: 13px;
}
</style>
